import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import StepCardBody from "@/features/create-agreement/common/components/StepCardBody";
import { Box, Indent, Space, Spacing, Txt } from "@stenajs-webui/core";
import { Card, CardBody, CardHeader, Label } from "@stenajs-webui/elements";
import { TextArea } from "@stenajs-webui/forms";
import { cssColor } from "@stenajs-webui/theme";

export const LastPageStep = ({
  lastPageText,
  setLastPageText,
}: {
  lastPageText: string;
  setLastPageText: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <>
      <CardHeader text="Last page information" />
      <StepCardBody width={"100%"}>
        <Txt>Text visible on the last page of the report.</Txt>
        <Space num={SpaceValues.TWENTYFOUR} />
        <Card>
          <CardBody>
            <Box
              background={cssColor("--lhds-color-blue-100")}
              borderColor={cssColor("--lhds-color-blue-300")}
              alignContent={"center"}
              maxHeight={"800px"}
            >
              <Space num={SpaceValues.SIXTEEN} />
              <Indent num={IndentValues.SIXTEEN}>
                <Label text={"Text to include"} optional />
              </Indent>
              <Box minHeight={"400px"}>
                <Indent num={IndentValues.SIXTEEN}>
                  <TextArea
                    value={lastPageText}
                    onValueChange={setLastPageText}
                    rows={20}
                  />
                </Indent>
                <Spacing num={SpacingValues.EIGHT} />
              </Box>
            </Box>
          </CardBody>
        </Card>
      </StepCardBody>
    </>
  );
};
