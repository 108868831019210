import { ErrorPage } from "@/LoginPage";
import { FeatureFlagsQuery } from "@/gql/graphql";
import { useQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";

const featureFlagsQuery = gql`
  query FeatureFlags {
    productPrice {
      features {
        pricing {
          usePricingV2
        }
      }
    }
  }
`;

export interface FeatureFlags {
  pricing: {
    usePricingV2: boolean;
  };
}

export interface FeatureFlagsContextType {
  featureFlags: FeatureFlags;
  setFeatureFlags: React.Dispatch<React.SetStateAction<FeatureFlags>>;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType | null>(null);

const FeatureFlagsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data, error } = useQuery<FeatureFlagsQuery>(featureFlagsQuery);

  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({
    pricing: { usePricingV2: false },
  });

  useEffect(() => {
    if (data) {
      setFeatureFlags(data.productPrice.features);
    }
  }, [data, setFeatureFlags]);

  if (error) {
    return <ErrorPage text={error.message} />;
  }

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags,
        setFeatureFlags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export { FeatureFlagsContext, FeatureFlagsProvider };
