import { ReportScreenCustomerFragment } from "@/gql/graphql";
import { Column, Txt } from "@stenajs-webui/core";
import { RadioButton } from "@stenajs-webui/forms";
import { TableCell, TableRow } from "@stenajs-webui/grid";
type CustomerContactsTableProps = {
  selectedCustomerId: string;
  customerContacts: ReportScreenCustomerFragment["subscriberContactsForReport"]["0"][];
  setSelectedCustomerSubscriber: React.Dispatch<
    React.SetStateAction<
      | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"]
      | undefined
    >
  >;
};
export const CustomerContactTable = ({
  customerContacts,
  setSelectedCustomerSubscriber,
  selectedCustomerId,
}: CustomerContactsTableProps) => {
  return (
    <CustomerTable
      setSelectedCustomerSubscriber={setSelectedCustomerSubscriber}
      customerContacts={customerContacts}
      selectedId={selectedCustomerId}
    />
  );
};

const CustomerTable = ({
  customerContacts,
  selectedId,
  setSelectedCustomerSubscriber,
}: {
  selectedId?: string;
  customerContacts:
    | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"][]
    | undefined;
  setSelectedCustomerSubscriber: React.Dispatch<
    React.SetStateAction<
      | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"]
      | undefined
    >
  >;
}) => {
  const width = 120;
  return (
    <Column>
      <TableRow borderStyle={"hidden"}>
        <TableCell width={50}>
          <RadioButton
            onChange={() => setSelectedCustomerSubscriber(undefined)}
            checked={!selectedId}
          />
        </TableCell>
        <TableCell width={50}>
          <Txt>None</Txt>
        </TableCell>
      </TableRow>
      {customerContacts &&
        customerContacts.map((customerContact) => (
          <TableRow borderStyle={"hidden"} key={customerContact.id}>
            <TableCell width={50}>
              <RadioButton
                onChange={() => setSelectedCustomerSubscriber(customerContact)}
                checked={
                  selectedId
                    ? selectedId === customerContact.subscriber.id
                    : false
                }
              />
            </TableCell>
            <TableCell minWidth={width}>
              <Txt>{customerContact.subscriber.firstName}</Txt>
            </TableCell>
            <TableCell minWidth={width}>
              <Txt>{customerContact.subscriber.surName}</Txt>
            </TableCell>
            <TableCell minWidth={width}>
              <Txt>
                {(customerContact.mobile
                  ? customerContact.mobile
                  : customerContact.subscriber.telNo) ?? ""}
              </Txt>
            </TableCell>
            <TableCell minWidth={width}>
              <Txt>{customerContact.email}</Txt>
            </TableCell>
          </TableRow>
        ))}
    </Column>
  );
};
