import { Box, Row } from "@stenajs-webui/core";

import { ReportScreenCustomerFragment } from "@/gql/graphql";
import { useRef } from "react";
import StepContainer from "../../common/components/StepContainer";
import { useGetContainerWidth } from "../hooks/getRowContainerWidth";
import { CustomerDetailsStep } from "./components/customer-details/CustomerDetailsStep";
import SpecificationsStep from "./components/specifications/SpecificationsStep";

type CustomerDetailsWithSpecificationsStepProps = {
  customerSubscribers: ReportScreenCustomerFragment["subscriberContactsForReport"];
  customer: ReportScreenCustomerFragment | null | undefined;
  reportType: String;
  selectedCustomerSubscriber:
    | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"]
    | undefined;
  setSelectedCustomerSubscriber: React.Dispatch<
    React.SetStateAction<
      | ReportScreenCustomerFragment["subscriberContactsForReport"]["0"]
      | undefined
    >
  >;
  customerId: string;
  year: number;
  month: number;
  headAgreementNumber?: number;
  modeOfPayment?: string;
  invoiceCurrency?: string;
  validFrom?: string;
  validTo?: string;
  includeCreditLimit: boolean;
  setIncludeCreditLimit: React.Dispatch<React.SetStateAction<boolean>>;
  includeThresholds: boolean;
  setIncludeThresholds: React.Dispatch<React.SetStateAction<boolean>>;
  specificationsText: string;
  setSpecificationsText: React.Dispatch<React.SetStateAction<string>>;
  setIsNextAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingReportForParent: boolean;
};

export const CustomerDetailsWithSpecificationsStep = ({
  customerSubscribers,
  reportType,
  customer,
  selectedCustomerSubscriber,
  setSelectedCustomerSubscriber,
  setIsNextAvailable,
  customerId,
  year,
  month,
  isCreatingReportForParent,
  headAgreementNumber,
  modeOfPayment,
  invoiceCurrency,
  validFrom,
  validTo,
  includeCreditLimit,
  setIncludeCreditLimit,
  includeThresholds,
  setIncludeThresholds,
  specificationsText,
  setSpecificationsText,
}: CustomerDetailsWithSpecificationsStepProps) => {
  const rowContainer = useRef<HTMLDivElement>(null);
  const { containerWidth } = useGetContainerWidth(rowContainer);

  return (
    <StepContainer>
      <Row width={"100%"} flexWrap={"wrap"} ref={rowContainer}>
        {isCreatingReportForParent && (
          <Box
            width={containerWidth}
            minWidth={"1000px"}
            justifyContent={"space-between"}
          >
            <CustomerDetailsStep
              customer={customer}
              reportType={reportType}
              customerSubscribers={customerSubscribers}
              selectedCustomerSubscriber={selectedCustomerSubscriber}
              setSelectedCustomerSubscriber={setSelectedCustomerSubscriber}
            />
          </Box>
        )}
        <Box
          width={isCreatingReportForParent ? containerWidth : "100%"}
          minWidth={"1000px"}
        >
          <SpecificationsStep
            setIsNextAvailable={setIsNextAvailable}
            customerId={customerId}
            year={year}
            month={month}
            isCreatingReportForParent={isCreatingReportForParent}
            headAgreementNumber={headAgreementNumber}
            modeOfPaymemt={modeOfPayment}
            invoiceCurrency={invoiceCurrency}
            validFrom={validFrom}
            validTo={validTo}
            includeCreditLimit={includeCreditLimit}
            setIncludeCreditLimit={setIncludeCreditLimit}
            includeThresholds={includeThresholds}
            setIncludeThresholds={setIncludeThresholds}
            specificationsText={specificationsText}
            setSpecificationsText={setSpecificationsText}
          />
        </Box>
      </Row>
    </StepContainer>
  );
};
