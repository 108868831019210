import { CardBody } from "@stenajs-webui/elements";

interface Props {
  width?: string;
}

const StepCardBody: React.FC<Props> = ({ children, width }) => (
  <CardBody width={width ?? "100%"} overflow={"auto"}>
    {children}
  </CardBody>
);
export default StepCardBody;
